<template>
  <ion-page>
    <GlobalToolbar :page="'search'" />

    <ion-content class="ion-padding">
    </ion-content>
  </ion-page>
</template>

<script>
/* Ionic */
import { IonPage, IonContent } from '@ionic/vue'

/* Components */
import GlobalToolbar from '@/components/Toolbar'

export default {
  name: 'search',

  components: {
    IonContent, IonPage,
    GlobalToolbar
  },

  setup() {
        
  },
}
</script>

<template>
  <ion-header >
    <ion-toolbar>
      <ion-title v-if="page=='notifications'">
        <span :style="'padding-right:1vw;'">Your</span> Notifications
      </ion-title>
      <ion-title v-else-if="page=='search'">
        Businesses making an <span :style="'padding-left:1vw;text-transform:lowercase'">Impact</span>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

/* Ionic */
import { IonHeader, IonToolbar, IonButton, IonTitle, } from '@ionic/vue';

export default {
  name: 'GlobalToolbar',

  components: {
    IonHeader, IonToolbar, IonButton, IonTitle
  },

  props: {
    page: {
      default: null,
      type: String
    },
  },

  setup() {
    const { state, dispatch } = useStore();
    const uid = computed(() => state.auth.uid);

    onMounted(() => {


    });
   
    return {
      uid
    };
  },

}
</script>

<style scoped>
ion-toolbar {
  --min-height: 55px;
}
ion-title {
  text-align:left;
  padding-inline: 5vw;
  font-weight:600;
  color:#004F7C;
  font-size:13px;
  text-transform:uppercase;
}
ion-title span {
  font-family: 'Satisfy', cursive;
  font-weight:normal;
  color:#DC8D83;
  font-size:25px;
  text-transform:capitalize;
}
</style>